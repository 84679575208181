import React, { useState, useEffect, forwardRef } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { getData } from "../../services/api.svs";
import { Auth } from 'aws-amplify';
import NotificationAlert from "react-notification-alert";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "assets/css/charts.css";
import { Button } from 'reactstrap';

function ConversationStatusTableComponent(props) {

  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0)
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date('2021'));
  const notificationAlertRef = React.useRef(null);

  const notify = (place, message, type) => {

    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const getStats = async (start, end) => {
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
    getData({ 'operation': 'conversationStatusTotalsForPeriod', 'min': start, 'max': end }, token).then(resp => {
      setItems(resp.data)
    }).catch(resp => {
      let message = (<div>
        <div>
          Error fetching Conversation Status data.
        </div>
      </div>)
      notify("tl", message, "danger")
      setItems([])
      console.log(resp)
    })
  }
  useEffect(() => {
    getStats(startDate, endDate)
  }, [])

  const handleChange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    if (newEndDate !== null && newEndDate !== undefined) {
      var date = new Date(newEndDate)
      var endDate = new Date(date.setMonth(date.getMonth() + 1, 0))
      setEndDate(endDate)
      getStats(newStartDate, endDate)
    }
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Button className="datepicker" onClick={onClick} ref={ref} >
      {value}
    </Button>
  ));

  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      <div
        style={{ marginTop: '1em', marginRight: '2em', float: 'right' }}>
        <DatePicker
          closeOnScroll={(e) => e.target === document}
          selected={startDate}
          onChange={handleChange}
          selectsRange
          startDate={startDate}
          endDate={endDate}
          dateFormat="MM-yyyy"
          showMonthYearPicker
          customInput={<ExampleCustomInput />}
        /></div>
      <TableContainer sx={{ maxHeight: 700 }}>
        <Table size="small"
        aria-label="simple table" id="table-conversation">
          <TableHead>
            <TableRow>
              <TableCell style={{ color: "white", fontWeight:"bolder", fontVariantCaps:"all-small-caps" }}>Status</TableCell>
              <TableCell style={{ color: "white", fontWeight:"bolder", fontVariantCaps:"all-small-caps"  }}>Conversations</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(items)
              .map((item) => {
                return (
                  <TableRow key={item.key}>
                    <TableCell style={{ color: "white" }}>{item.key}</TableCell>
                    <TableCell style={{ color: "white" }}>{item.value}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ConversationStatusTableComponent;
