import React, { useState, useEffect, forwardRef } from 'react';

import { Bar, Pie } from "react-chartjs-2";
import { getData } from "../../services/api.svs";
import { Auth } from 'aws-amplify';
import NotificationAlert from "react-notification-alert";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "assets/css/charts.css";
import { Button } from 'reactstrap';

function ConversationStatusComponent(props) {

  const [data, setData] = useState([]);
  const [label, setLabel] = useState([]);
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date('2021'));
  const notificationAlertRef = React.useRef(null);

  const notify = (place, message, type) => {

    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };


  let status_options = {
    maintainAspectRatio: false,
    legend: {
      display: true
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    },
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        // align: 'start',
        text: 'Conversation Status'
      }
    }
  };

  let statusChart = {
    stats: (canvas) => {
      return {
        labels: label,
        datasets: [
          {
            label: "conversations",
            backgroundColor: ['rgba(255, 159, 64, 0.2)'],
            borderColor: ['rgba(255, 159, 64, 1)'],
            borderWidth: 1,
            data: data
          }
        ]
      };
    },
    options: status_options
  };

  const getStats = async (start, end) => {
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
    getData({ 'operation': 'conversationStatusForPeriod', 'min': start, 'max': end }, token).then(resp => {
      setData([])
      setLabel([])
      for (const [key, value] of Object.entries(resp.data)) {
        setData(data => [...data, value.value]);
        setLabel(label => [...label, value.key]);
        console.log(key)
        console.log(value)
      }
    }).catch(resp => {
      let message = (<div>
        <div>
          Error fetching Conversation Status data.
        </div>
      </div>)
      notify("tl", message, "danger")
      console.log(resp)
    })
  }
  useEffect(() => {
    getStats(startDate, endDate)
  }, [])

  const handleChange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    if (newEndDate !== null && newEndDate !== undefined) {
      var date = new Date(newEndDate)
      var endDate = new Date(date.setMonth(date.getMonth() + 1, 0))
      setEndDate(endDate)
      console.log(newStartDate)
      console.log(newEndDate)
      console.log(endDate)
      getStats(newStartDate, endDate)
    }
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Button className="datepicker" onClick={onClick} ref={ref} >
      {value}
    </Button>
  ));

  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      <div
        style={{ marginTop: '1em', marginRight: '2em', float: 'right' }}>
        <DatePicker
          closeOnScroll={(e) => e.target === document}
          selected={startDate}
          onChange={handleChange}
          selectsRange
          startDate={startDate}
          endDate={endDate}
          dateFormat="MM-yyyy"
          showMonthYearPicker
          customInput={<ExampleCustomInput />}
        /></div>
      <Bar
        data={statusChart["stats"]}
        options={statusChart.options}
      />
    </>
  );
};

export default ConversationStatusComponent;
