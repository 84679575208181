/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4
} from "variables/charts.js";
import AgesComponent from "components/Dashboard/Ages";
import ConversationStatusTableComponent from "components/Dashboard/ConversationStatusTable";
import QualifiedComponent from "components/Dashboard/Qualified";
import ConversationStatusComponent from "components/Dashboard/ConversationStatus";
import ConversationMonthlyComponent from "components/Dashboard/ConversationMonthly";
import ConversationStatusMonthlyComponent from "components/Dashboard/ConversationStatusMonthly";

function Dashboard(props) {


  return (
    <>
      <div className="content">
        {/* <Row style={{marginBottom: '1em' }}>
          <Col xs="12">
            <Card className="card-chart" style={{ height: '100%'}}>
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category">Conversation Stats</h5>
                  </Col>

                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <ConversationStatusMonthlyComponent />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row> */}
        <Row>
          <Col lg="4">
            <Row>
            <Card className="card-chart" style={{marginBottom: '15px'}}>
              <CardHeader>
                <h5 className="card-category">Client Stats</h5>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <AgesComponent />
                </div>
                <div className="chart-area" style={{marginBottom: '40px'}}>
                  <QualifiedComponent />
                </div>
              </CardBody>
            </Card>
            </Row>
            <Row>
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Conversation Stats</h5>
              </CardHeader>
              <CardBody>
                <div className="chart-area" style={{marginBottom: '40px'}}>
                  <ConversationStatusTableComponent />
                </div>
              </CardBody>
            </Card>
            </Row>
          </Col>
          {/* <Col lg="4">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Clients</h5>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <QualifiedComponent />
                </div>
              </CardBody>
            </Card>
          </Col> */}
          <Col lg="8">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Conversation Stats</h5>
              </CardHeader>
              <CardBody>
                <div className="chart-area" style={{marginBottom: '55px'}}>
                  <ConversationStatusMonthlyComponent />
                </div>
              {/* </CardBody>
              <CardBody> */}
                <div className="chart-area" style={{marginBottom: '55px'}}>
                  <ConversationMonthlyComponent />
                </div>
                <div className="chart-area" style={{marginBottom: '52px'}}>
                  <ConversationStatusComponent />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
