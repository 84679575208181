import Dashboard from "views/Dashboard.js";
import Clients from "views/Clients";
import ClientOverview from "views/ClientOverview";
// import Users from "views/Users.js";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/admin",
    show: true
  },
  {
    path: "/clients",
    name: "Clients",
    rtlName: "Clients",
    icon: "tim-icons icon-molecule-40",
    component: Clients,
    layout: "/admin",
    show: true
  },{
    path: "/overview",
    name: "Client Overview",
    rtlName: "Client Overview",
    icon: "tim-icons icon-badge",
    component: ClientOverview,
    layout: "/admin",
    show: false
  }
];
export default routes;
