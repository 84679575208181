import React, { useState, useEffect, forwardRef } from 'react';

import { Line, Pie } from "react-chartjs-2";
import { getData } from "../../services/api.svs";
import { Auth } from 'aws-amplify';
import NotificationAlert from "react-notification-alert";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "assets/css/charts.css";
import { Button } from 'reactstrap';

function ConversationStatusMonthlyComponent(props) {

  const [data, setData] = useState([]);
  const [ongoing, setOngoing] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [abandoned, setAbandoned] = useState([]);
  const [partial, setPartial] = useState([])
  const [label, setLabel] = useState([]);
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 6)));
  const notificationAlertRef = React.useRef(null);

  const notify = (place, message, type) => {

    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  let status_options = {
    maintainAspectRatio: false,
    legend: {
      display: true
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    },
    scales: {
      yAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent"
          },
          ticks: {
            suggestedMin: 60,
            suggestedMax: 125,
            padding: 20,
            fontColor: "#9a9a9a"
          }
        }
      ],
      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.1)",
            zeroLineColor: "transparent"
          },
          ticks: {
            padding: 20,
            fontColor: "#9a9a9a"
          }
        }
      ]
    },
    responsive: true,
    plugins: {
      title: {
        display: true,
        // align: 'start',
        text: 'Conversation Statuses for Range'
      }
    }
  };

  let statusChart = {
    stats: (canvas) => {
      return {
        labels: label,
        datasets: [
          {
            label: "Ongoing",
            backgroundColor: ['rgba(255, 99, 132, 0.2)'],
            borderColor: [
              'rgba(255, 99, 132, 1)'],
            borderWidth: 1,
            data: ongoing,
            lineTension: 0.4,
            fill: true
          },
          {
            label: "Completed",
            backgroundColor: ['rgba(54, 162, 235, 0.2)'],
            borderColor: [
              'rgba(54, 162, 235, 1)'],
            borderWidth: 1,
            data: completed,
            lineTension: 0.4,
            fill: true
          }, {
            label: "Abandoned",
            backgroundColor: ['rgba(75, 192, 192, 0.2)'],
            borderColor: [
              'rgba(75, 192, 192, 1)'],
            borderWidth: 1,
            data: abandoned,
            lineTension: 0.4,
            fill: true
          }, {
            label: "Partially Completed",
            backgroundColor: ['rgba(255, 206, 86, 0.2)'],
            borderColor: [
              'rgba(255, 206, 86, 1)'],
            borderWidth: 1,
            data: partial,
            lineTension: 0.4,
            fill: true
          }
        ]
      };
    },
    options: status_options
  };

  const getStats = async (start, end) => {
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
    getData({'operation': 'monthlyConversationStatus', 'min': start, 'max': end }, token).then(resp => {
      setData([])
      setLabel([])
      setOngoing([])
      setAbandoned([])
      setCompleted([])
      setPartial([])
      for (const [key, value] of Object.entries(resp.data.labels)) {
        setLabel(label => [...label, value]);
      }

      for (const [keyset, valueset] of Object.entries(resp.data.dataset)) {
        for (const [key, value] of Object.entries(valueset.data)){
       if (valueset.label == "Ongoing") setOngoing(ongoing => [...ongoing, value]);
       if (valueset.label == "Completed") setCompleted(completed => [...completed, value]);
       if (valueset.label == "Abandoned") setAbandoned(abandoned => [...abandoned, value]);
       if (valueset.label == "Partially Completed") setPartial(partial => [...partial, value])
        }
      }
    }).catch(resp => {
      let message = (<div>
        <div>
          Error fetching Conversation Statuses for Range data.
        </div>
      </div>)
      notify("tl", message, "danger")
      console.log(resp)
    })
  }

  useEffect(() => {
    getStats(startDate, endDate)
  }, [])

  const handleChange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    if (newEndDate !== null && newEndDate !== undefined) {
      var date = new Date(newEndDate)
      var endDate = new Date(date.setMonth(date.getMonth() +1, 0))
      setEndDate(endDate)
      var flag = new Date(newEndDate) - new Date(newStartDate) <= 31622400000;
      if (flag) {
        getStats(newStartDate, endDate)
      } else {
        setEndDate(new Date(new Date(newStartDate).setMonth(new Date(newStartDate).getMonth() + 12, 0)))
        let message = (<div>
          <div>
            One year maximum on datepicker. Setting to one year range.
          </div>
        </div>)
        notify("tl", message, "danger")
        getStats(newStartDate, new Date(new Date(newStartDate).setMonth(new Date(newStartDate).getMonth() + 12, 0)))
      }
    }
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Button className="datepicker" onClick={onClick} ref={ref} >
      {value}
    </Button>
  ));

  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      <div
        style={{ marginTop: '1em', marginRight: '2em', float: 'right' }}>
        <DatePicker
          closeOnScroll={(e) => e.target === document}
          selected={startDate}
          onChange={handleChange}
          selectsRange
          startDate={startDate}
          endDate={endDate}
          dateFormat="MM-yyyy"
          showMonthYearPicker
          customInput={<ExampleCustomInput />}
        /></div>
      <Line
        data={statusChart["stats"]}
        options={statusChart.options}
      />
    </>
  );
};

export default ConversationStatusMonthlyComponent;
