import React, { useState, useEffect } from 'react';
import { getItem, getFileItem, getData } from "../services/api.svs";
import { Auth } from 'aws-amplify';
import { Link } from "react-router-dom";
import NotificationAlert from "react-notification-alert";

import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardText,
    CardFooter,
    Table,
    Row,
    Col,
    Button
} from "reactstrap";
import { consoleSandbox } from '@sentry/utils';

function ClientOverview(props) {
    const [hasContactInfo, setHasContactInfo] = useState(false);
    const [conversationState, setConversation] = useState([])
    const [messagesState, setMessages] = useState([])
    const notificationAlertRef = React.useRef(null)
    const notify = (place, message, type) => {
        //  type = "primary";
        // type = "success";
        // type = "danger";
        // type = "warning";
        // type = "info";

        var options = {};
        options = {
            place: place,
            message: (
                message
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        notificationAlertRef.current.notificationAlert(options);
    };
    // console.log(props.location.state);

    useEffect(() => {
        let conversation = [];
        let messages = [];
        let message = (<div>
            <div>
                Error retrieved getting the data
            </div>
        </div>)

        const getConversation = async () => {
            const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
            await getData({ 'operation': 'contact', 'contactid': `${props.location.state.contactid}` }, token).then(resp => {
                console.log(props.location.state)
                setConversation(resp.data.contact)
                console.log(resp.data.contact)
                if (props.location.state.fullname !== null && props.location.state.fullname !== undefined) {
                    setHasContactInfo(true)
                }
                // console.log(conversation);
                // getData({'operation':'conversation', 'contactid': `${props.location.state.contactid}`}, token).then(resp => {
                messages = resp.data.messages;
                setMessages(messages)
                console.log(messages)
                // }).catch(resp => {
                //     setMessages({})
                //     setIsLoaded(true)
                //     notify("tl", message, "danger")
                // })

            }).catch(resp => {
                setConversation([])
                setMessages({})
                // setIsLoaded(true)
                notify("tl", message, "danger")
            })
            //stop loading when data is fetched
        }
        getConversation()

    }, [])

    // .then(response => response.blob()).then(response =>

    const onButtonClick = async () => {
        const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
        getData({ 'operation': 'download', 'file_type': 'contact', 'contactid': `${props.location.state.contactid}` }, token).then(resp => {
            var signed_url = resp.data
            getFileItem(signed_url).then(response => {
                const filename = "Contact_File_" + props.location.state.contactid +".csv";
                // response.blob().then(blob => {
                let url = window.URL.createObjectURL(new Blob([response.data]));
                let a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();
                //   }).catch(resp => {
                //     console.log(resp)
                // });
            });
        });
    }

    const onConversationClick = async () => {
        const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
        getData({ 'operation': 'download', 'file_type': 'conversation', 'contactid': `${props.location.state.contactid}` }, token).then(resp => {
            var signed_url = resp.data
            getFileItem(signed_url).then(response => {
                const filename = "Conversation_File_" + props.location.state.contactid +".csv";
                // response.blob().then(blob => {
                let url = window.URL.createObjectURL(new Blob([response.data]));
                let a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();
                //   }).catch(resp => {
                //     console.log(resp)
                // });
            });
        });
    }

    const listItems = messagesState.map((message) =>
        <>
            <Card style={{ textAlign: message.sender === "Profmed" ? "right" : "left" }}>
                <CardHeader>{message.timestamp}</CardHeader>
                <CardBody>
                    <CardTitle tag="h5">
                        {message.sender}
                    </CardTitle>
                    <CardText>
                        {message.text_message}
                    </CardText>
                </CardBody>
            </Card>
        </>
    );

    var start = new Date(`${conversationState.startTime}`);
    var end = new Date(`${conversationState.endTime}`);
    // console.log(date)
    const startDate = start.toLocaleDateString();
    const startTime = start.toLocaleTimeString();
    const endTime = end.toLocaleTimeString();

    return (
        <>
            <NotificationAlert ref={notificationAlertRef} />
            <div className="content">
            {hasContactInfo ? (<Row>
                    <Col md="4">
                        <Card className="card-user">
                            <CardBody>
                                <CardText />
                                <div className="author">
                                    <div className="block block-one" />
                                    <div className="block block-two" />
                                    <div className="block block-three" />
                                    <div className="block block-four" />
                                    <h5 className="title">{props.location.state.firstname} {props.location.state.surname} {props.location.state.AgeRange !== null && props.location.state.AgeRange !== undefined ? <>, ({props.location.state.AgeRange})</> : <></>}</h5>
                                    {props.location.state.Profession}
                                    <p className="description">{props.location.state.ContactInfo}</p>
                                </div>
                                <div className="card-description">
                                    <span>{props.location.state.Qualified === null || props.location.state.Qualified === undefined 
                                    ? <><p>No qualification data</p></> : (props.location.state.Qualified === 'yes' ? <><p>Qualified</p></> : (props.location.state.Qualified === 'no' ? <><p>Unqualified</p></> : <><p>Qualification unsure</p></>))}</span>
                                    {props.location.state.MoreThanOneDependent === null || props.location.state.MoreThanOneDependent === undefined ? <></> : (props.location.state.MoreThanOneDependent === 'yes' ? <><p>Two or more dependents</p></> : <><p>One or fewer dependents</p></>)}
                                    {props.location.state.preferredCommunication === null || props.location.state.preferredCommunication === undefined ? <></> : <><p>{props.location.state.preferredCommunication} preferred</p></>}
                                    <p>{props.location.state.cover}</p>
                                </div>
                            </CardBody>
                            <CardFooter>
                                {props.location.state.package}

                                <div className="button-container">
                                    <Button className="btn-icon btn-round" onClick={onButtonClick}>
                                        <i className="tim-icons icon-cloud-download-93" />
                                    </Button>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>) : <></>}

                <Row>
                    <Card>
                        <CardBody><div className="button-container"><span style={{ paddingRight: "2em" }}>Conversation on {startDate} from {startTime} to {endTime}</span>

                            <Button className="btn-icon btn-round" onClick={onConversationClick}>
                                <i className="tim-icons icon-cloud-download-93" />
                            </Button>
                        </div>
                        </CardBody>
                    </Card>
                </Row>

                {listItems}
            </div>
            {/* } */}
        </>
    );
}

export default ClientOverview;