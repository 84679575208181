import React, { useState, useEffect } from 'react';
import { getData, getFileItem, getItem, requestFileItem } from "../services/api.svs";
import { Auth } from 'aws-amplify';
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input
} from "reactstrap";
import { Button } from '@aws-amplify/ui-react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';

function Clients() {
  const [IsLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([])
  const [filtered, setFiltered] = useState([])
  const [filter, setFilter] = useState(false)
  const [show, setShow] = useState(false);
  const notificationAlertRef = React.useRef(null);

  const notify = (place, message, type) => {

    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  useEffect(() => {
    const getClients = async () => {
      const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
      getData({ 'operation': 'contacts' }, token).then(resp => {
        console.log(resp.data)
        setItems(resp.data)
        setIsLoaded(true)
      }).catch(resp => {
        setItems([])
        setIsLoaded(true)
        let message = (<div>
          <div>
            Error retrieved getting the data
          </div>
        </div>)
        notify("tl", message, "danger")
      })
      //stop loading when data is fetched
    }
    getClients()
  }, [])

  const onButtonClick = async (contactid) => {
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
    getData({ 'operation': 'download', 'file_type': 'contact', 'contactid': `${contactid}` }, token).then(resp => {
      var signed_url = resp.data
      getFileItem(signed_url).then(response => {
        const filename = "Contact_File_" + contactid + ".csv";
        // response.blob().then(blob => {
        let url = window.URL.createObjectURL(new Blob([response.data]));
        let a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
        //   }).catch(resp => {
        //     console.log(resp)
        // });
      });
    });
    getData({ 'operation': 'download', 'file_type': 'conversation', 'contactid': `${contactid}` }, token).then(resp => {
      var signed_url = resp.data
      getFileItem(signed_url).then(response => {
        const filename = "Conversation_File_" + contactid + ".csv";
        // response.blob().then(blob => {
        let url = window.URL.createObjectURL(new Blob([response.data]));
        let a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
        //   }).catch(resp => {
        //     console.log(resp)
        // });
      });
    });
  }

  //on Search Data in Table 
  const onSearchData = (e) => {
    var searchData = [];
    if (e.target.value !== "") {
      function find(arr) {
        var result = [];
        for (var i in arr) {
          var firstname = arr[i].firstname;
          var first_name = arr[i].first_name;
          var surname = arr[i].surname;
          var conversation_status = arr[i].conversation_status;
          var benefit = arr[i].package;
          if ((firstname !== null && firstname !== undefined ? firstname.toLowerCase().match(e.target.value.toLowerCase()) : false) ||
          (first_name !== null && first_name !== undefined ? first_name.toLowerCase().match(e.target.value.toLowerCase()) : false) ||
            (surname !== null && surname !== undefined ? surname.toLowerCase().match(e.target.value.toLowerCase()) : false) ||
            (conversation_status !== null && conversation_status !== undefined ? conversation_status.toLowerCase().match(e.target.value.toLowerCase()) : false) ||
            (benefit !== null && benefit !== undefined ? benefit.toLowerCase().match(e.target.value.toLowerCase()) : false)
            ) {
            result.push(arr[i]);
          }
        }
        return result;
      }
      searchData = find(items);
      setFiltered(searchData)
      setFilter(true)
    } else {
      searchData = [];
      setFilter(searchData)
      setFilter(false)
    }
  }


  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card style={{ position: "sticky", top: 50, backgroundColor: "rgba(30, 30, 47, 1) !important", zIndex: 0 }}>
              <CardHeader>
                <CardTitle tag="h4">Clients</CardTitle>
                <p className="category">Information gathered by the bot about potential clients.</p>

                <div style={{ padding: "2em" }}>
                  <Input
                    placeholder="Search..."
                    onChange={(e) => onSearchData(e)}
                  />
                </div>
              </CardHeader>

            </Card>

            <Card className='card-plain'>
              <Paper sx={{ width: '100%', overflow: 'hidden', backgroundColor: "transparent" }}>
                <TableContainer sx={{ maxHeight: 700 }}>
                  <Table stickyHeader aria-label="sticky table" id="table-client">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Surname</TableCell>
                        <TableCell>Age Bracket</TableCell>
                        <TableCell>Benefit</TableCell>
                        <TableCell>Conversation Status</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(filter ? filtered : items)
                        .map((item) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={item.contactid}>
                              <TableCell style={{ color: "white" }}>{item.firstname !== null && item.firstname !== undefined ? item.firstname : item.first_name}</TableCell>
                              <TableCell style={{ color: "white" }}>{item.surname}</TableCell>
                              <TableCell style={{ color: "white" }}>{item.AgeRange}</TableCell>
                              <TableCell style={{ color: "white" }}>
                                {item.PackageSuggestion}
                              </TableCell>
                              <TableCell style={{ color: "white" }}>
                                {item.conversation_status}
                              </TableCell>
                              <TableCell>
                                <div className="button-container">
                                  <Link to={{
                                    pathname: '/overview',
                                    // search: '?',
                                    state: item
                                  }}>
                                    <Button><i className="tim-icons icon-chat-33" style={{ color: "rgba(75, 192, 192, 1)" }} /></Button></Link> &nbsp;
                                  <Button onClick={() => onButtonClick(item.contactid)}>
                                    <i className="tim-icons icon-cloud-download-93" style={{ color: "rgba(75, 192, 192, 1)" }} />
                                  </Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Clients;